const noop = () => null

const Memory = (key, getStorage) => {
  if (typeof window === 'undefined') {
    return {
      recall: noop,
      remember: noop,
      forget: noop
    }
  }

  const storage = getStorage()

  return {
    recall () {
      const str = storage.getItem(key)

      if (!str) {
        return null
      }

      try {
        return JSON.parse(str)
      } catch (e) {
        console.error(e)

        return null
      }
    },

    remember (val) {
      return storage.setItem(key, JSON.stringify(val))
    },

    forget () {
      return storage.removeItem(key)
    }
  }
}

export default Memory
