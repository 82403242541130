import React from 'react'
import { AuthenticatedUserApiProvider } from './src/utils/authentication'

export const wrapRootElement = ({ element }) => {
  return (
    <AuthenticatedUserApiProvider>
      {element}
    </AuthenticatedUserApiProvider>
  )
}
