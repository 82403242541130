import React, { useContext, useState, useEffect } from 'react'
import fetch from 'unfetch'

export const ApiContext = React.createContext()

const dataDefaults = {
  loading: false,
  error: null,
  data: null
}

class CancelledPromise extends Error {}

const globalCache = {}

export const ApiProvider = ({ apiUrl, httpHeaders, children }) => {
  const getFullUrl = url => {
    return url.match(/^\//) ? `${apiUrl}${url}` : url
  }

  const httpClient = makeHttpClient(httpHeaders, getFullUrl)

  return (
    <ApiContext.Provider
      value={{
        cache: globalCache,
        httpClient
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}

export const ApiLoader = ({ onUpdate, url }) => {
  const result = useApi(url)

  const {
    data,
    loading,
    error
  } = result

  useEffect(() => {
    onUpdate(result)
  }, [data, loading, error])

  return null
}

const makeHttpClient = (httpHeaders, getFullUrl) => {
  const headers = {
    'Content-type': 'application/json',
    ...httpHeaders
  }

  const errorHandler = (res, url) => {
    if (!res.ok) {
      throw new Error(`Response ${res.status} from ${url}`)
    }

    return res.json()
  }

  return {
    async get (url) {
      const fullUrl = getFullUrl(url)

      const res = await fetch(fullUrl, {
        method: 'GET',
        headers
      })

      return errorHandler(res, fullUrl)
    },

    async post (url, data) {
      const fullUrl = getFullUrl(url)

      const res = await fetch(fullUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
      })

      return errorHandler(res, fullUrl)
    }
  }
}

export const useHttpClient = () => {
  return useContext(ApiContext).httpClient
}

export const useApi = (url, useCache = true) => {
  const { cache, httpClient } = useContext(ApiContext)

  if (!cache[url]) {
    cache[url] = () => {
      const [ state, setState ] = useState(dataDefaults)

      if (typeof window === 'undefined') {
        return dataDefaults
      }

      useEffect(() => {
        setState({
          ...state,
          data: null,
          loading: true
        })

        let hasBeenCancelled = false

        const request = useCache ? cfetch(httpClient, url) : httpClient.get(url)

        request
          .then(data => {
            if (hasBeenCancelled) {
              throw new CancelledPromise()
            }

            return data
          })
          .then(data => {
            setState({ ...state, loading: false, data })
          })
          .catch(e => {
            if (e instanceof CancelledPromise) {
              return
            }

            console.error('Error response in request')
            console.error(e)

            setState({ ...state, loading: false, error: e })
          })

        return () => {
          hasBeenCancelled = true
        }
      }, [url])

      return state
    }
  }

  return cache[url]()
}

/**
 * works ok, but probably causes unexpected results for some users
 * @param {obj} cache
 */
const cachedFetch = cache => async (httpClient, url) => {
  const cacheKey = `GET,${url}`
  const cachedVal = cache[cacheKey]

  if (cachedVal) {
    return cachedVal
  }

  const data = await httpClient.get(url)

  cache[cacheKey] = data

  return data
}

const cfetch = cachedFetch({})
