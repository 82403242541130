import React from 'react'
import fetch from 'unfetch'
import { navigate } from 'gatsby'
import Memory from './memory'
import { ApiProvider } from './api'
import { API_URL } from './config'

const {
  recall,
  remember,
  forget
} = Memory('authToken', () => window.localStorage)

export const getAuthToken = () => {
  return recall()
}

export const forgetToken = () => {
  forget()
}

export const isLoggedIn = () => {
  return !!getAuthToken()
}

export const handleGoogleCallback = async queryString => {
  const token = await getAccessTokenFromAuthorizationCodeFlow(queryString)

  remember(token)
}

export const performLogin = () => {
  window.location = `${API_URL}/login/google`
}

export const getAccessTokenFromAuthorizationCodeFlow = async queryString => {
  const url = `${API_URL}/login/google/auth${queryString}`

  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
  })

  if (!resp.ok) {
    console.error(resp)
    throw new Error(`response not ok from server: ${resp.status}`)
  }

  const json = await resp.json()

  return json.authToken
}

export const Private = ({ children }) => {
  if (!isLoggedIn()) {
    if (typeof window !== 'undefined') {
      navigate('/login')
    }

    return null
  }

  return children
}

export const AuthenticatedUserApiProvider = props => {
  const authToken = getAuthToken()

  return (
    <ApiProvider
      apiUrl={API_URL}
      httpHeaders={{
        'Authorization': `Bearer ${authToken}`
      }}
      {...props}
    />
  )
}
